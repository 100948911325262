<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      {{ $t("add_new_applicants") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="card nice-shadow-18">
             
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addApplicantsPage",
  components: {},
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    routeName: {
      type: String,
      default: "applicantsadd",
    },
    apiPath: {
      type: String,
      default: "applicants/add",
    },
  },
  data() {
    return {
      formData: {
        application_ref: "",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return this.$t("add_new_applicants");
      },
    },
  },
  methods: {
    ...mapActions("applicants", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["applicant_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/applicants`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = {
        account_username: "",
        account_email: "",
        account_password: "",
        confirm_password: "",
        account_photo: "",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
